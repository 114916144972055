<template>
  <ion-page id="offers-page">
    <ion-content :fullscreen="true">
      <div class="offers-top" v-if="!$store.state.isPC">
        <!-- <div class="statusBar" ref="statusBar"></div> -->
        <div class="home-bar" style="padding-bottom: 20px" ref="topMenu">
          <div class="bar-title" :class="{ 'letter-ios': $store.state.deviceType == 1 }">
            {{ $t("offers.Offers") }}
          </div>
        </div>
      </div>
      <div
        class="pc-box margin-top-0 pc-box2"
        :class="{ 'offer-content-app': !$store.state.isPC }"
      >
        <div class="pc-min-content-pct">
          <div
            style="margin: 50px 0 50px 14px"
            class="pc-page-title"
            v-if="$store.state.isPC"
          >
            {{ $t("offers.Offers") }}
          </div>

          <van-tabs
            v-model="activeName"
            :ellipsis="false"
            ref="offersTabs"
            :line-width="lineWidth"
            @click-tab="tabsEvent"
            class="offers-tabs"
          >
            <!-- Coupon -->
            <van-tab :title="$t('offers.eCoupons')" name="85px">
              <div class="section" v-if="rewardList.length">
                <div v-if="!$store.state.isPC">
                  <ion-card
                    class="slide-card"
                    v-for="(item, i) in rewardList"
                    :key="i"
                    @click="goRewardDetail(item)"
                  >
                    <img v-lazy="item.coverImg" :key="item.coverImg" />
                    <ion-card-header class="card-header">
                      <ion-card-title class="eCouponTitle">{{
                        item.name
                      }}</ion-card-title>
                      <ion-card-subtitle
                        class="eCouponSubTitle letter-ios-07"
                        v-if="item.redeemEndTime && item.redeemEndTime != ''"
                        >{{ $t("offers.ValidTill") }}
                        {{
                          formatDate(
                            item.redeemEndTime.replaceAll("-", "/"),
                            $store.state.form.merchantDetail.dateFormat
                          )
                        }}</ion-card-subtitle
                      >
                    </ion-card-header>
                  </ion-card>
                  <ion-infinite-scroll
                    @ionInfinite="loadData"
                    threshold="200px"
                    id="infinite-scroll"
                    :disabled="isDisabled2"
                  >
                    <ion-infinite-scroll-content
                      loading-spinner="bubbles"
                      loading-text="Loading more data..."
                    >
                    </ion-infinite-scroll-content>
                  </ion-infinite-scroll>
                </div>

                <div class="banner-content" v-if="$store.state.isPC">
                  <swiper
                    :width="400"
                    :modules="modules"
                    :pagination="$store.state.isPC ? true : false"
                    :navigation="
                      $store.state.isPC
                        ? {
                            nextEl: '.next2',
                            prevEl: '.prev2',
                          }
                        : false
                    "
                    @swiper="onSwiper2"
                  >
                    <swiper-slide v-for="(item, i) in rewardList" :key="i">
                      <ion-card
                        class="slide-card special-height"
                        @click="goRewardDetail(item)"
                      >
                        <div class="coverImgBgW">
                          <img :src="item.coverImg" />
                        </div>
                        <ion-card-header class="card-header">
                          <ion-card-title class="eCouponTitle">{{
                            item.name
                          }}</ion-card-title>
                          <ion-card-subtitle
                            class="eCouponSubTitle letter-ios-07"
                            v-if="item.redeemEndTime && item.redeemEndTime != ''"
                            >{{ $t("offers.ValidTill") }}
                            {{
                              formatDate(
                                item.redeemEndTime.replaceAll("-", "/"),
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}</ion-card-subtitle
                          >
                        </ion-card-header>
                      </ion-card>
                    </swiper-slide>
                  </swiper>
                  <div v-if="$store.state.isPC" class="left-btn next2">
                    <img src="@/assets/btn-back.png" />
                  </div>
                  <div v-if="$store.state.isPC" class="right-btn prev2">
                    <img src="@/assets/btn-prev.png" />
                  </div>
                </div>
              </div>
              <div class="noData" v-else>
                <span v-if="!$store.state.form.token">{{
                  $t("offers.Registernowformoreoffers")
                }}</span>
                <div v-else>
                  <span >{{ $t("offers.CouponComingsoon1") }} </span> <br> <span> {{ $t("offers.CouponComingsoon2") }} </span><br> <span>{{ $t("offers.CouponComingsoon3") }}</span>
                </div>
              </div>
            </van-tab>

            <!-- Collector Cards -->
            <van-tab :title="$t('offers.ShopEarn')" name="125px">
              <div class="section" v-if="collectCardList.length">
                <div v-if="!$store.state.isPC">
                  <ion-card
                    class="slide-card"
                    v-for="(item, i) in collectCardList"
                    :key="i"
                    @click="goDetail('collectorCardDetail', item.id)"
                  >
                    <img v-lazy="item.coverImg" :key="item.coverImg" />
                    <ion-card-header class="card-header">
                      <ion-card-title class="eCouponTitle">{{
                        item.name
                      }}</ion-card-title>
                      <div class="center-item">
                        <ion-card-subtitle class="eCouponSubTitle letter-ios-07"
                          >{{ $t("offers.ValidTill") }}
                          {{
                            formatDate(
                              item.collEndTime.replaceAll("-", "/"),
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}</ion-card-subtitle
                        >
                        <div class="row-item">
                          <img v-lazy="item.stampImg" :key="item.stampImg" />
                          <div class="stamp">
                            {{ item.collectStampQty }}/{{ item.singleCardCollStampQty }}
                          </div>
                        </div>
                      </div>
                    </ion-card-header>
                    <div class="card-list">
                      <div
                        class="card-item"
                        v-for="(card, cardIndex) in item.collectList"
                        :key="cardIndex"
                      >
                        <div class="card-num" v-show="!card.stampImg">
                          {{ card.num }}
                        </div>
                        <div class="card-itemImg" v-show="card.stampImg">
                          <img v-lazy="card.stampImg" :key="card.stampImg" />
                        </div>
                      </div>
                    </div>
                  </ion-card>
                  <ion-infinite-scroll
                    @ionInfinite="loadData"
                    threshold="200px"
                    id="infinite-scroll"
                    :disabled="isDisabled1"
                  >
                    <ion-infinite-scroll-content
                      loading-spinner="bubbles"
                      loading-text="Loading more data..."
                    >
                    </ion-infinite-scroll-content>
                  </ion-infinite-scroll>
                </div>
                <div class="banner-content" v-if="$store.state.isPC">
                  <swiper
                    :width="400"
                    :modules="modules"
                    :pagination="$store.state.isPC ? true : false"
                    :navigation="
                      $store.state.isPC
                        ? {
                            nextEl: '.next1',
                            prevEl: '.prev1',
                          }
                        : false
                    "
                    @swiper="onSwiper1"
                  >
                    <swiper-slide
                      v-for="(item, i) in collectCardList"
                      :key="i"
                      @click="goDetail('collectorCardDetail', item.id)"
                    >
                      <ion-card class="slide-card">
                        <!-- <img v-lazy="item.coverImg" :key="item.coverImg" /> -->
                        <div class="coverImgBgW">
                          <img :src="item.coverImg" />
                        </div>
                        <ion-card-header class="card-header">
                          <ion-card-title class="eCouponTitle">{{
                            item.name
                          }}</ion-card-title>
                          <div class="center-item">
                            <ion-card-subtitle
                              class="eCouponSubTitle letter-ios-07"
                              v-if="item.collEndTime && item.collEndTime != ''"
                              >{{ $t("offers.ValidTill") }}
                              {{
                                formatDate(
                                  item.collEndTime.replaceAll("-", "/"),
                                  $store.state.form.merchantDetail.dateFormat
                                )
                              }}</ion-card-subtitle
                            >
                            <div class="row-item" v-if="$store.state.form.token">
                              <img :src="item.stampImg" />
                              <div class="stamp">
                                {{ item.collectStampQty }}/{{
                                  item.singleCardCollStampQty
                                }}
                              </div>
                            </div>
                          </div>
                        </ion-card-header>
                        <div class="card-list">
                          <div
                            class="card-item"
                            v-for="(card, cardIndex) in item.collectList"
                            :key="cardIndex"
                          >
                            <div class="card-num" v-show="!card.stampImg">
                              {{ card.num }}
                            </div>
                            <div class="card-itemImg" v-show="card.stampImg">
                              <img v-lazy="card.stampImg" :key="card.stampImg" />
                            </div>
                          </div>
                        </div>
                      </ion-card>
                    </swiper-slide>
                  </swiper>
                  <div v-if="$store.state.isPC" class="left-btn next1">
                    <img src="@/assets/btn-back.png" />
                  </div>
                  <div v-if="$store.state.isPC" class="right-btn prev1">
                    <img src="@/assets/btn-prev.png" />
                  </div>
                </div>
              </div>
              <div class="noData" v-else>
                <span v-if="!$store.state.form.token">{{
                  $t("offers.Registernowformoreoffers")
                }}</span>
                <div v-else>

                <span>{{ $t("offers.EarnComingsoon1") }}</span> <br> <span> {{ $t("offers.EarnComingsoon2") }}</span> <br> <span> {{ $t("offers.EarnComingsoon3") }}</span>
                </div>
              </div>
            </van-tab>

            <!-- Stamp -->
            <van-tab :title="$t('offers.eStamps')" name="55px">
              <div class="section" v-if="stampList.length">
                <div v-if="!$store.state.isPC">
                  <ion-card
                    class="slide-card"
                    v-for="(item, i) in stampList"
                    :key="i"
                    @click="goDetail('stampCardDetail', item.campaignId)"
                  >
                    <img v-lazy="item.coverImg" :key="item.coverImg" />
                    <ion-card-header class="card-header">
                      <ion-card-title class="eCouponTitle">{{
                        item.name
                      }}</ion-card-title>
                      <div class="center-item">
                        <ion-card-subtitle
                          class="eCouponSubTitle letter-ios-07"
                          v-if="item.redeemEndTime && item.redeemEndTime != ''"
                          >{{ $t("offers.ValidTill") }}
                          {{
                            formatDate(
                              item.redeemEndTime.replaceAll("-", "/"),
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}</ion-card-subtitle
                        >
                        <div class="row-item stampItem">
                          <img src="@/assets/stamp-no.png" />
                          <div class="stampNo Bold">
                            {{
                              item.collectStampQty
                                ? checkStampNum(item.collectStampQty)
                                : 0
                            }}
                          </div>
                        </div>
                      </div>
                    </ion-card-header>
                  </ion-card>
                  <ion-infinite-scroll
                    @ionInfinite="loadData"
                    threshold="200px"
                    id="infinite-scroll"
                    :disabled="isDisabled3"
                  >
                    <ion-infinite-scroll-content
                      loading-spinner="bubbles"
                      loading-text="Loading more data..."
                    >
                    </ion-infinite-scroll-content>
                  </ion-infinite-scroll>
                </div>

                <div class="banner-content" v-if="$store.state.isPC">
                  <swiper
                    :width="400"
                    :modules="modules"
                    :pagination="$store.state.isPC ? true : false"
                    :navigation="
                      $store.state.isPC
                        ? {
                            nextEl: '.next3',
                            prevEl: '.prev3',
                          }
                        : false
                    "
                    @swiper="onSwiper3"
                  >
                    <swiper-slide v-for="(item, i) in stampList" :key="i">
                      <ion-card
                        class="slide-card"
                        @click="goDetail('stampCardDetail', item.campaignId)"
                      >
                        <!-- <img v-lazy="item.coverImg" :key="item.coverImg" /> -->
                        <div class="coverImgBgW">
                          <img :src="item.coverImg" />
                        </div>
                        <ion-card-header class="card-header">
                          <ion-card-title class="eCouponTitle">{{
                            item.name
                          }}</ion-card-title>
                          <div class="center-item">
                            <ion-card-subtitle
                              class="eCouponSubTitle letter-ios-07"
                              v-if="item.redeemEndTime && item.redeemEndTime != ''"
                              >{{ $t("offers.ValidTill") }}
                              {{
                                formatDate(
                                  item.redeemEndTime.replaceAll("-", "/"),
                                  $store.state.form.merchantDetail.dateFormat
                                )
                              }}</ion-card-subtitle
                            >
                            <div
                              class="row-item stampItem"
                              v-if="$store.state.form.token"
                            >
                              <img src="@/assets/stamp-no.png" />
                              <div class="stampNo Bold">
                                {{ item.collectStampQty ? item.collectStampQty : 0 }}
                              </div>
                            </div>
                          </div>
                        </ion-card-header>
                      </ion-card>
                    </swiper-slide>
                  </swiper>
                  <div v-if="$store.state.isPC" class="left-btn next3">
                    <img src="@/assets/btn-back.png" />
                  </div>
                  <div v-if="$store.state.isPC" class="right-btn prev3">
                    <img src="@/assets/btn-prev.png" />
                  </div>
                </div>
              </div>
              <div class="noData" v-else>
                <span v-if="!$store.state.form.token">{{
                  $t("offers.Registernowformoreoffers")
                }}</span>
                <div v-else>
                <span >{{ $t("offers.EStampsComingsoon1") }}</span> <br> <span> {{ $t("offers.EStampsComingsoon2") }}</span> <br> <span> {{ $t("offers.EStampsComingsoon3") }}</span>
                </div>
              </div>
            </van-tab>
          </van-tabs>
          <div class="height50" v-if="isDisabled1 && isDisabled2 && isDisabled3"></div>
        </div>
      </div>
      <wallet-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCard,
  IonCardHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import config from "../config";
import walletBtn from "@/components/walletBtn.vue";
export default {
  name: "OffersPage",
  mixins: [config.globalMixin],
  components: {
    IonPage,
    IonContent,
    walletBtn,
    IonCardSubtitle,
    IonCardTitle,
    IonCard,
    // IonSlides,
    // IonSlide,
    IonCardHeader,
    Swiper,
    SwiperSlide,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  data() {
    return {
      activeName: "85px",
      lineWidth: "85px",
      isDisabled1: false,
      isDisabled2: false,
      isDisabled3: false,
      modules: [Navigation, Pagination],
      slideOpts: {
        initialSlide: 0,
        slidesPerView: 1.032,
        width: "400",
        centeredSlides: true,
      },
      slideOptsLoop: {
        autoplay: {
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        loop: false,
        initialSlide: 0,
        slidesPerView: 1.032,
        width: "400",
        centeredSlides: true,
      },
      logo: require("@/assets/logo.png"),
      eCouponList: [],
      rewardList: [],
      rewardListData: [],
      collectCardList: [],
      stampList: [],
      pageLimit: 20,
      rewardLimit: 20,
      pageNum1: 0,
      pageNum2: 0,
      pageNum3: 0,
      swiper1: null,
      swiper2: null,
      swiper3: null,
    };
  },
  methods: {
    tabsEvent(name) {
      this.isDisabled = false;
      this.activeName = name.name;
      this.pageNum = 0;
      if (!this.$store.state.isPC) {
        this.lineWidth = name.name;
      } else {
        // if (name.title == "eCoupons") {
        // }
        this.lineWidth = "15%";
      }
      this.sendGAEvent("Click", "Offers", "Tabs", name);
    },
    onSwiper1(swiper) {
      this.swiper1 = swiper;
    },
    onSwiper2(swiper) {
      this.swiper2 = swiper;
    },
    onSwiper3(swiper) {
      this.swiper3 = swiper;
    },
    loadData(e) {
      if (this.activeName == "125px") {
        this.pageNum1++;
        this.getListUserStampCard(e);
      } else if (this.activeName == "85px") {
        if (!this.isDisabled2) {
          this.pageNum2++;
        }
        if (this.$store.state.form.token) {
          // this.getListUserCoupon(e);
          this.getRewardList(e);
        } else {
          this.getListCoupon(e);
        }
      } else if (this.activeName == "55px") {
        this.pageNum3++;
        this.getListCampaign(e);
      }
    },
    // updateSlideHeight(e) {
    //   let swiperFn = e.target.swiper;
    //   let domArr = Array.from(swiperFn.slides);
    //   let maxHeight = 0;
    //   domArr.forEach(item => {
    //     if (item.children[0].offsetHeight > maxHeight) {
    //       maxHeight = item.offsetHeight;
    //     }
    //   });
    //   domArr.forEach(el => {
    //     el.children[0].style.height = maxHeight + "px";
    //   });
    // },
    goDetail(type, id) {
      this.$store.state.form.backPage = "/main/offers";
      this.checkToken("/main/offers", () => {
        this.$router.push({
          path: "/" + type + "/" + id,
        });
      });
      this.sendGAEvent("Click", "Offers", type, id);
    },
    goRewardDetail(item) {
      this.$store.state.form.backPage = "/main/offers";
      let id;
      if (item.type == 1) {
        // coupon
        if (this.$store.state.form.token) {
          id = item.couponId;
        } else {
          id = item.id;
        }
        this.$router.push({
          path: "/couponDetail/" + id,
        });
      } else {
        this.checkToken("/main/offers", () => {
          this.$router.push({
            path: "/collectorCardDetail/" + item.id,
          });
        });
      }

      this.sendGAEvent(
        "Click",
        "Offers",
        item.type == 1 ? "couponDetail" : "collectorCardDetail",
        id
      );
    },
    async getRewardList(e) {
      if (this.isDisabled2) {
        e.target.complete();
        return;
      }
      if (this.rewardListData == [] || this.rewardListData.length == 0) {
        const objdata = this.getAuthObj();
        objdata.userCouponType = 1;
        objdata.apiUrl = this.apiConfig.listUserReward;
        const data = await this.$store.dispatch("apiGetEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
        this.rewardListData = data.data.result;
      }
      let nowNumData = [];

      for (let z = 0; z < this.rewardLimit; z++) {
        if (
          this.rewardListData[this.pageNum2 * this.rewardLimit + z] &&
          this.rewardListData[this.pageNum2 * this.rewardLimit + z].id
        ) {
          nowNumData.push(this.rewardListData[this.pageNum2 * this.rewardLimit + z]);
        }
      }
      this.rewardList = [...this.rewardList, ...nowNumData];

      this.$store.state.form.rewardList = this.rewardList;
      if (e) {
        e.target.complete();
      }
      if (this.rewardList.length >= this.rewardListData.length) {
        this.isDisabled2 = true;
      }
    },
    async getListCoupon(e) {
      const objdata = this.getAuthObj();
      objdata.pageLimit = this.pageLimit;
      objdata.pageNum = this.pageNum2;
      objdata.apiUrl = this.apiConfig.listCoupon;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      data.data.result.forEach((item) => {
        this.eCouponList.push(item);
      });
      this.$store.state.form.eCouponList = this.eCouponList;
      if (e) {
        e.target.complete();
      }
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled2 = true;
      }
    },
    async getListUserCoupon(e) {
      const objdata = this.getAuthObj();
      objdata.pageLimit = this.pageLimit;
      objdata.pageNum = this.pageNum2;
      objdata.userCouponType = 1;
      objdata.apiUrl = this.apiConfig.listUserCoupon;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      data.data.result.forEach((item) => {
        this.eCouponList.push(item);
      });
      this.$store.state.form.eCouponList = this.eCouponList;
      if (e) {
        e.target.complete();
      }
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled2 = true;
      }
    },
    async getListCampaign(e) {
      const objdata = this.getAuthObj();
      objdata.pageLimit = this.pageLimit;
      objdata.pageNum = this.pageNum3;
      objdata.apiUrl = this.apiConfig.listCampaign;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }

      data.data.result.forEach((item) => {
        this.stampList.push(item);
      });
      if (e) {
        e.target.complete();
      }
      this.$store.state.form.stampList = this.stampList;
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled3 = true;
      }
    },
    async getListUserStampCard(e) {
      const objdata = this.getAuthObj();
      objdata.pageLimit = this.pageLimit;
      objdata.pageNum = this.pageNum1;
      objdata.apiUrl = this.apiConfig.listUserStampCard;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }

      let itemsPerPage = 10;
      data.data.result.forEach((item) => {
        item.collectList = [];
        let currentPage =
          item.collectStampQty != 0 ? Math.ceil(item.collectStampQty / itemsPerPage) : 1;

        let start, end, imgTimes;

        if (currentPage === 1) {
          start = 1;
          end = Math.min(item.singleCardCollStampQty, itemsPerPage);
        } else {
          start = itemsPerPage * (currentPage - 1) + 1;
          end = Math.min(itemsPerPage * currentPage, item.singleCardCollStampQty);
        }
        imgTimes = item.collectStampQty - start + 1;
        console.log(start, end);

        for (let i = 0; i < 10; i++) {
          item.collectList[i] = {};
          if (i < imgTimes) {
            item.collectList[i].stampImg = item.stampImg;
          }
          item.collectList[i].num = start + i;
        }
        this.collectCardList.push(item);
      });

      if (e) {
        e.target.complete();
      }
      this.$store.state.form.collectCardList = this.collectCardList;
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled1 = true;
      }
    },
  },
  ionViewDidEnter() {
    this.clickTabEvent("offers");
    this.pageNum1 = 0;
    this.pageNum2 = 0;
    this.pageNum3 = 0;
    this.isDisabled1 = false;
    this.isDisabled2 = false;
    this.isDisabled3 = false;
    this.eCouponList = [];
    this.stampList = [];
    this.rewardListData = [];
    this.rewardList = [];
    this.collectCardList = [];

    try {
      if (this.swiper1) {
        this.swiper1.slideTo(0);
        this.swiper2.slideTo(0);
        this.swiper3.slideTo(0);
      }
    } catch (error) {
      console.log(error);
    }

    if (this.$store.state.isReward) {
      if (this.$refs.offersTabs != null) {
        this.$store.state.isReward = false;
        this.$refs.offersTabs.scrollTo("85px");
        this.lineWidth = "85px";
      }
    }
    // push to collectcard list
    if (this.$store.state.getNotification != null && this.$refs.offersTabs != null) {
        this.$refs.offersTabs.scrollTo("125px");
        this.lineWidth = "125px";
        this.$refs.offersTabs.resize();

    }

    if (this.$store.state.connected) {
      this.getListCampaign();
      if (this.$store.state.form.token) {
        this.getListUserStampCard();
        // this.getListUserCoupon();
        this.getRewardList();
      } else {
        this.getListCoupon();
      }
    } else {
      this.eCouponList = this.$store.state.form.eCouponList;
      this.rewardList = this.$store.state.form.rewardList;
      this.stampList = this.$store.state.form.stampList;
      this.collectCardList = this.$store.state.form.collectCardList;
    }
    setTimeout(() => {
      if (this.$refs.offersTabs != null) {
        this.$refs.offersTabs.resize();
      }
    }, 300);
    this.$store.state.exitApp = false;

    // setTimeout(() => {
    //   let hi1 = $("#me-page").attr("class").search("ion-page-hidden") > -1 || false;
    // if (this.$route.path == "/main/offers") {
    //   $("#more-page").addClass("ion-page-hidden");
    //   $("#more-page").css("zIndex", "100");
    //   $("#landingPage").addClass("ion-page-hidden");
    //   $("#landingPage").css("zIndex", "100");
    //   $("#me-page").addClass("ion-page-hidden");
    //   $("#me-page").css("zIndex", "100");
    //   $("#offers-page").removeClass("ion-page-hidden");
    //   $("#offers-page").css("zIndex", "101");
    // }
    // }, 100);
    this.sendGAPageView("Offers");
    
  },
  ionViewWillEnter() {
    // console.log("offers", new Date().getTime());
  },
  //   ionViewWillEnter() {
  //     setTimeout(() => {
  //       //   let hi1 = $("#me-page").attr("class").search("ion-page-hidden") > -1 || false;
  //       if (this.$route.path == "/main/offers") {
  //         $("#more-page").addClass("ion-page-hidden");
  //         $("#more-page").css("zIndex", "100");
  //         $("#landingPage").addClass("ion-page-hidden");
  //         $("#landingPage").css("zIndex", "100");
  //         $("#me-page").addClass("ion-page-hidden");
  //         $("#me-page").css("zIndex", "100");
  //         $("#offers-page").removeClass("ion-page-hidden");
  //         $("#offers-page").css("zIndex", "101");
  //       }
  //     }, 100);
  //   },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.card-list {
  padding-left: 5%;
  height: 120px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  place-content: center;
  .card-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 21px;
    color: #b6b6b6;
    box-sizing: content-box;
    border: 2px dashed #c5c5c5;
    border-radius: 50%;
    text-align: center;
    .card-num {
    }
    .card-itemImg {
      width: 36px;
      height: 36px;
      img {
        width: 100px;
        height: 100%;
      }
    }
  }
}
</style>
